import React, { useState, useEffect } from 'react';
import { ArrowRight, Feather, Leaf, Droplet, Wind, Globe } from 'lucide-react';

const IncubateWebsite = () => {
    const [scrolled, setScrolled] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleScroll = () => setScrolled(window.scrollY > 20);
        const handleMouseMove = (e) => setMousePosition({ x: e.clientX, y: e.clientY });

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="min-h-screen bg-[#FFFAF0] selection:bg-[#8B4513] selection:text-[#F5E6D3] font-['Manrope']">
            {/* Import Fonts */}
            <link
                href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
                rel="stylesheet"
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Cervantis&display=swap"
                rel="stylesheet"
            />

            {/* Navigation */}
            <nav className={`fixed w-full z-50 transition-all duration-500 ${scrolled ? 'py-4 bg-[#FFFAF0]/80 backdrop-blur-lg' : 'py-6 bg-transparent'}`}>
                <div className="max-w-screen-xl mx-auto px-6 flex items-center justify-between">
                    <a href="#" className="flex items-center gap-2">
                        <Feather className="h-5 w-5 stroke-[#8B4513] stroke-1" />
                        <div className="flex items-center gap-2">
                            <span className="text-lg tracking-tight text-[#8B4513]">incubate</span>
                            <span className="text-sm text-[#1B4D3E] px-2 py-0.5 rounded-full border border-[#1B4D3E]/30">beta</span>
                        </div>
                    </a>
                    <button className="text-sm text-[#1B4D3E] hover:text-[#1B4D3E]/60 transition-colors">
                        Join the Nest
                    </button>
                </div>
            </nav>

            {/* Hero */}
            <section className="min-h-screen flex items-center justify-center px-6 relative">
                <div className="max-w-screen-lg mx-auto text-center">
                    <div className="space-y-8">
                        <h1 className="text-5xl md:text-7xl font-light leading-tight">
                            <span className="text-black">A sanctuary for</span>
                            <br />
                            <span className="italic text-[#1B4D3E]">feathered dreams</span>
                        </h1>
                        <p className="text-lg md:text-xl text-black/60 max-w-2xl mx-auto">
                            Where ideas are nurtured with precision and care, until they're ready to take flight and create ripples of transformation.
                        </p>
                        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 pt-4">
                            <button className="group flex items-center gap-2 px-6 py-3 bg-[#1B4D3E] text-white hover:bg-[#1B4D3E]/90 transition-colors rounded-full">
                                Begin Your Journey
                                <ArrowRight className="h-4 w-4 group-hover:translate-x-0.5 transition-transform" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/* Vision Section with Enhanced Ripples */}
            <section className="py-32 px-6">
                <div className="max-w-screen-lg mx-auto">
                    <div className="grid md:grid-cols-2 gap-16 items-center">
                        <div>
                            <h2 className="text-3xl font-light mb-6 text-black">Beyond the ordinary</h2>
                            <p className="text-black/60 leading-relaxed mb-6">
                                In a world saturated with incremental improvements, we foster quantum leaps. Incubate is where exceptional minds converge, creating ripples that transform industries and redefine possibilities.
                            </p>
                            <button className="group inline-flex items-center gap-2 text-sm text-[#1B4D3E] hover:text-[#1B4D3E]/60 transition-colors">
                                Explore our thesis
                                <ArrowRight className="h-4 w-4 group-hover:translate-x-0.5 transition-transform" />
                            </button>
                        </div>
                        <div className="aspect-square bg-[#F5E6D3] rounded-2xl shadow-sm flex items-center justify-center overflow-hidden">
                            <div className="relative w-96 h-96">
                                <div className="absolute inset-0 animate-ripple border-2 border-[#9B6B43]/30 rounded-full"></div>
                                <div className="absolute inset-0 animate-ripple-delay-1 border-2 border-[#9B6B43]/30 rounded-full"></div>
                                <div className="absolute inset-0 animate-ripple-delay-2 border-2 border-[#9B6B43]/30 rounded-full"></div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-[#9B6B43] rounded-full" />
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 bg-[#9B6B43]/10 rounded-full animate-pulse"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Global Impact Section */}
            <section className="py-32 px-6 bg-[#1B3F8B] text-white">
                <div className="max-w-screen-lg mx-auto text-center">
                    <Globe className="h-12 w-12 mx-auto mb-8 opacity-80" />
                    <h2 className="text-3xl font-light mb-6">Creating waves of change</h2>
                    <p className="text-white/60 max-w-2xl mx-auto mb-12">
                        From local innovations to global transformations, every ripple contributes to the ocean of progress. Our network spans continents, connecting visionaries across borders.
                    </p>
                    <button className="group inline-flex items-center gap-2 px-6 py-3 bg-white text-[#1B3F8B] hover:bg-white/90 transition-colors rounded-full">
                        Explore Our Impact
                        <ArrowRight className="h-4 w-4 group-hover:translate-x-0.5 transition-transform" />
                    </button>
                </div>
            </section>

            {/* Natural Elements Section */}
            <section className="py-32 px-6 bg-[#1B4D3E] text-[#F5E6D3]">
                <div className="max-w-screen-lg mx-auto space-y-16">
                    <div className="text-center">
                        <h2 className="text-3xl font-light">Elements of growth</h2>
                    </div>
                    <div className="grid md:grid-cols-3 gap-12">
                        {[
                            {
                                icon: Leaf,
                                title: "Nurture",
                                description: "A refuge for the rarefied, where creativity runs deep and minds prodigious take soaring leaps."
                            },
                            {
                                icon: Droplet,
                                title: "Transform",
                                description: "Where intellectual boundaries are overthrown and the impossible is made possible with unrivalled precision."
                            },
                            {
                                icon: Wind,
                                title: "Elevate",
                                description: "An elite coalition destined to inspire, where feathered dreams finally take flight."
                            }
                        ].map((element, i) => (
                            <div key={i} className="space-y-4 group">
                                <element.icon className="h-8 w-8 text-[#F5E6D3]/80 group-hover:text-[#F5E6D3] transition-colors duration-300" />
                                <h3 className="text-xl font-light">{element.title}</h3>
                                <p className="text-[#F5E6D3]/60 leading-relaxed">{element.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Call to Action */}
            <section className="py-32 px-6 bg-white">
                <div className="max-w-screen-sm mx-auto text-center space-y-8">
                    <h2 className="text-3xl font-light text-black">Take flight with us</h2>
                    <p className="text-black/60">
                        Join a sanctuary where ingenuity and design become living tradition, where the future of tomorrow is forever instilled.
                    </p>
                    <form className="flex flex-col sm:flex-row gap-4 justify-center">
                        <input
                            type="email"
                            placeholder="Your email"
                            className="px-4 py-3 bg-black/5 rounded-full focus:outline-none focus:ring-2 focus:ring-black transition-all"
                        />
                        <button className="whitespace-nowrap px-6 py-3 bg-black text-white hover:bg-black/90 transition-colors rounded-full">
                            Join the Nest
                        </button>
                    </form>
                </div>
            </section>

            {/* Footer */}
            <footer className="py-12 px-6 border-t border-[#8B4513]/10 bg-[#FFFAF0]">
                <div className="max-w-screen-lg mx-auto">
                    <div className="flex items-center justify-center gap-8 mb-8">
                        <div className="flex items-center gap-2">
                            <Feather className="h-4 w-4 stroke-[#8B4513] stroke-1" />
                            <span className="text-sm text-[#8B4513]">incubate</span>
                        </div>
                        <a href="#" className="text-sm text-[#8B4513]/60 hover:text-[#8B4513] transition-colors">about</a>
                        <a href="#" className="text-sm text-[#8B4513]/60 hover:text-[#8B4513] transition-colors">contact</a>
                    </div>
                    <div className="text-center space-y-2">
                        <div className="text-sm text-[#8B4513]/60">
                            © 2024 Incubate. All rights reserved.
                        </div>
                        <div className="text-sm text-[#8B4513]/40">
                            A product of <a href="#" className="hover:text-[#8B4513] transition-colors">Angalifu</a>
                        </div>
                    </div>
                </div>
            </footer>

            <style jsx>{`
                @keyframes ripple {
                    0% { transform: scale(0.1); opacity: 0.8; }
                    50% { opacity: 0.4; }
                    100% { transform: scale(1); opacity: 0; }
                }
                
                .animate-ripple {
                    animation: ripple 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                }
                
                .animate-ripple-delay-1 {
                    animation: ripple 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                    animation-delay: 1s;
                }
                
                .animate-ripple-delay-2 {
                    animation: ripple 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
                    animation-delay: 2s;
                }
            `}</style>
        </div>
    );
};

export default IncubateWebsite;